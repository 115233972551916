.patients-list--thead> {
    border: none;
}
.patients-list--thead>tr>th {
    font-weight: 400 !important;
    font-size: 0.9rem !important;
}

.patients-list--row>td {
    padding: 1rem 0 1rem 0 !important;
    font-weight: 400 !important;
    margin-left: 1rem;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: #ffffff;
   box-shadow: none;
}

.table-striped>tbody>tr:nth-child(even)>td, 
.table-striped>tbody>tr:nth-child(even)>th {
   background-color: rgba(158, 120, 237, 0.1);
   box-shadow: none;
    &:hover {
        background-color: rgba(158, 120, 237, 0.1);
    }
}

.patients-list--letter-circle {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
}