.navbar--item {
    color: #6C757D !important;
}
.navbar--item:hover {
    color: #6C757D !important;
    background-color: #E7F1FF;
}

.navbar--item-active {
    background-color: rgba(231, 241, 255, 0.5) !important;
    color: #0D6EFD !important;
    /*Not working for some reason..*/
    font-weight: 600 !important;
}

.navbar--item-button-active:hover {
    background-color: rgba(231, 241, 255, 0.6) !important;
    color: #0D6EFD !important;
}