.custom-statistics-border {
    border: 1px solid lightgrey;
    padding: 10px;
    border-radius: 4px;
}

.custom-tabs .nav-tabs {
justify-content: flex-end;
}

.custom-tabs .nav-link {
color: #6C757D;
}

.custom-tabs .nav-link.active {
color: rgba(0, 0, 0, 0.707);
background-color: rgba(0, 0, 0, 0.05);
border-color: rgba(0, 0, 0, 0.05);
}