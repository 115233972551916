.edit-exercise--table-icon {
    color: #017BFE;
    cursor: pointer;
    font-size: 1.2rem;
}

.exercise--table-col {
    min-width: 6rem;
}

.exercise-library--secondary-button {
    background-color: #fff !important;
    color: #6C757D !important;
    border-style: solid !important;
    border-color: #CED4DA !important;
    /* padding: 0.1rem 0.5rem 0.1rem 0.5rem; */
}

.exercise-library--secondary-button:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
      color: #6C757D !important;
      border-style: solid !important;
      border-color: #CED4DA !important;
}

.exercise-library--primary-outline-button {
    background-color: #fff !important;
    color: #0D6EFD !important;
    border-style: solid;
    border-color: #CED4DA !important;
}

.exercise-library--primary-outline-button:hover {
      background-color: rgba(231, 241, 255, 0.5) !important;
      color: #0D6EFD !important;
      border-style: solid;
      border-color: #0D6EFD !important;
}

.exercise-library--filter-button {
    color: #212529;
}

.exercise-library--filter-button:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-toggle::after {
    display: none !important; 
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border-style: solid !important;
    border-color: #CED4DA !important;
}

.input-group-text {
    background-color: #fff;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link {
    border: none;
    color: #212529;
}

.nav-tabs .nav-link.active {
    color: #0D6EFD;
    font-weight: 600;
    border-radius: 4px;
    border-color: rgba(231, 241, 255, 0.5);
    background-color: rgba(231, 241, 255, 0.5);
}

.exercise--library-select-footer {
    background-color: #FAFAFA;
    border-top: 2px solid #0D6EFD;
    padding: 1rem;
}

@media only screen and (max-width: 820px) {
    .exercise-library--card-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, min(100%, 330px));
        grid-gap: 1.5rem;
        justify-content: left;
    }
} 

@media only screen and (min-width: 821px) { 
    .exercise-library--card-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, min(100%, 400px));
        grid-gap: 1.5rem;
        justify-content: left;
    }
}
