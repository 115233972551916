.exerciseCard--card {
    cursor: pointer;
    max-width: 100% !important;
}

.exerciseCard--card:hover {
    box-shadow: 2px 2px 2px #CED4DA;
}

.exerciseCard--img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.exerciseCard--img-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;    
    /* background: rgba(43, 184, 179, 0.7); */
    z-index: 99;
    border-radius: 3px;
    border: 1px solid rgba(51, 51, 51, 0.1);
}

.exerciseCard--card-img {
    object-fit: cover;
    max-width: 100% !important;
    max-height: 250px !important;    
}

.exerciseCard--difficulty-icon {
    color: #9E77ED;
    margin-left: 0.4rem;
}

.exerciseCard--answer-format-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: #9E77ED;
}

.exerciseCard--answer-format-icon-patient {
    padding: 3rem;
    font-size: 8rem;
    color: #9E77ED;
}

.exercise--card-icon-image {
    display: inline;
    color: #9E77ED;
    font-size: 1.25rem;
    margin-left: 0.5rem;
}

.exercise--card-progress-bar {
    height: 3rem;
}

.exercise--card-progress-sub-bar-complete {
    background-color: #198754;
}

.exercise--card-progress-sub-bar-incomplete {
     background-color: #ECE4FB;
}

.exercise--card-number-icon {
    color: #6C757D;

    .exercise--card-corner-box {
        top: -1.25rem;
        left: -1.25rem;
        position: absolute;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.25rem;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
        border: 1px solid #6C757D;
        background-color: #ffffff;
    }
}

