.exercise-library--row {
    vertical-align: middle;
    font-weight: bold;
    background-color: #ffffff;
}

.exercise-library--table-row-title {
    font-size: 1.1rem;
}

.exercise-library--table-row-type-col {
    width: 3rem;
    position: relative;
    overflow: hidden; 
}

.exercise-library--table-row-action-col {
    width: 3rem;
}

.exercise-library--row-base-lib-icon {
    .corner-box {
        background-color: rgba(158, 120, 237, 0.2)
    }
    .icon {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 0.7rem;
    }
}

.exercise-library--row-own-exercise-icon {
    color: #E765B1;

    .corner-box {
        background-color: rgba(231, 101, 177, 0.2);
    }
    .icon {
        transform: scale(0.6);
        position: absolute;
        top: -2px;
        right: -2px;
    }
}

.exerciseLibraryCard--row-community-icon {
    color: #17A3AB;

    .corner-box {
        background-color: rgba(23, 163, 171, 0.2);
    }
    .icon {
        transform: scale(0.6);
        position: absolute;
        top: -2px;
        right: -2px;
    }
}

.corner-box {
    top: -50px;
    right: -50px;
    position: absolute;
    height: 5rem;
    width: 5rem;
    transform: rotate(45deg);
}

.exercise-library--table-exercise-select {
    transform: scale(1.2);
}
