.dashboard--content-div {
    margin-top: 4rem;
    background-color: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dashboard--section {
    background-color: #ffffff;
    border-radius: 0.5rem;
    margin: 2rem;
    padding: 3rem;
}