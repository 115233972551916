.exercise--div-audio {         
    margin: 0.5rem;
}

.exercise--large-audio-btn {
    height: 5rem;
    width: 5rem;
    border-radius: 100%;
    font-size: 1rem;
}

.exercise--avatar-audio-btn {
    height: 12rem;
    width: 25rem;
    border: none; 
    background: none !important;   
}

.audio--play-icon {
    font-size: 0.6rem;
    padding: 0.3rem 0.2rem;
    border-radius: 100%;
    border: 2px solid #0D6EFD;
}

.inline-audio-button {
    border-color: #CED4DA;
    display: inline-flex;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
    background-color: #ffffff;
}

.inline-audio-button:hover {
    border-color: #0D6EFD;

    .audio--play-icon {
        border: 2px solid #ffffff;
    }
}
