.exercise--close-btn {
    margin-right: 1rem !important;
    vertical-align: middle;
}

.exercise--div-question {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    line-height: 100%;
    font-size: 2.5rem;
    font-weight: bold;
    word-break: break-word;
}

.exercise--div-question-noimg {    
    font-size: 4.5rem;    
    min-height: 25rem;
}

.exercise--div-comp-question-noimg {    
    font-size: 3rem;
    min-height: 19rem;
}

.exercise--div-comp-listen-question-noimg {    
    text-align: center;
    min-height: 19rem;
}

.exercise--div-comp-audio-subtitle {
    text-align: center;
}

.exercise--div-img {
    display: flex;
    justify-content: center;
}

.exercise--img {
    height: auto;
    max-height: 350px;
    max-width: 100%;
    border-radius: 5px;
}

.exercise--feedback-writtenAnswer { 
    font-weight: 400;
    font-size: 1.5rem;
    background-color: #f5f1fd;
    border: 1px solid #f5f1fd;
    border-radius: calc(.25rem - 1px);
}

.exercise--img-feedback-visible {
    visibility: visible;
}

.exercise--img-feedback-label {
    font-size: 1.5rem;
    font-weight: 900;
}

.exercise--img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.exercise--btn {
    font-size: 1.2rem;
    padding: 1.5rem 4rem !important;
}

.exercise--motivationalpopup {
    background-color: rgb(158, 119, 237);
    color: #fcfcfc;
}

.exercise--motivationalpopup-text {
    font-size: 3rem;
}

.exercise-finished-ribbon-container {
    position: relative;
    text-align: center;
}

.exercise-finished-ribbon-text {
    color: white;
    font-size: 1.7rem;
    position: absolute;
    top: 37.5%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.exercise-finished--text-big {
    font-size: 6rem;
}

.exercise-finished--text-medium {
    font-size: 2.5rem;
}

.exercise-finished-card {
    width: 14rem;
    border-radius: 1rem;
}

.exercise--record-button {
    width: 6rem;
    height: 6rem;
    font-size: 3rem;
}

.exercise--write-input {
    font-size: 1.5rem;
    height: 100%;
}

.exercise--write-button {
    font-size: 1.5rem;
}

.exercise--secondary-button {
    height: 4rem;
    width: 4rem;
    border-radius: 100%;
}

.exercise--hint-children {
    background: none;
    border: none !important;    
    height: 8rem;
}

.exercise--hint-children:focus, .exercise--hint-children:hover {
    background: none;
    border: none !important;    
    transform: scale(1.1);
    animation: hint-animation 0.5s linear;
}

@keyframes hint-animation {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }
}

.exercise--alert-repeat {
    text-align: center;
    font-size: 2rem;
}

.exercise--next-button {
    padding: 1rem;
}

.exercise--next-button-icon {
    padding-right: 1rem;
}

.exercise--answer-body {
    padding: 0 !important;
}

.exercise--container {
    display:flex !important;
    flex-direction: column !important;
}

.exercise--header-bg {
    background-color: rgba(158, 119, 237, 0.1);
}

.exercise--header-button {
    background-color: #ffffff;
    display: inline-flex;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
}

.exercise--header-button:hover {
    .audio--play-icon {
        border: 2px solid #ffffff;
    }

}

.exercise--header-description-popover {
    max-width: 500px !important;
}

.exercise--footer-bg {    
    background-color: rgba(158, 119, 237, 0.1);
    padding-top: 1.25rem;
    flex-grow: 1 !important;    
}

.exercise--footer-fixed {
    background-color: rgba(158, 119, 237, 0.1);
    padding: 2rem;
    max-height: 15rem;
}

.exercise--title {
    font-size: 1.2rem;
}

.exercise--progress-bar1 {
    background-color: rgb(158, 119, 237);
}

.exercise--progress-bar2 {
    background-color:rgba(158, 119, 237, 0.2);
}

.exercise--confidenceScore-wrapper {}


.exercise--confidenceScore-animation {    
    -webkit-transition: all 3s;
    -moz-transition: all 3s;
    -o-transition: all 3s;
    transition: all 3s;  
}

.exercise--feedbackIncorrect-icon {    
    font-size: 4rem;
    color: #F04438;
    animation-iteration-count: 1;
}

.exercise--feedbackCorrect-icon {
    font-size: 4rem;
    color: #12B76A; 
    animation-iteration-count: 1;
}

.exercise--feedback-title-text {
    color: #6C757D;
    font-size: 0.8rem;
}

 /* Extra small devices (phones, 600px and down, horizontal) */
 @media only screen and (min-height: 1000px) {
    .exercise--img {        
        max-height: 700px;
    }

    .exercise--feedback {
        height: 600px;
    }

    .exercise--div-question {        
        font-size: 2.5rem;
    }

    .exercise--div-question-noimg {        
        font-size: 5rem;    
        line-height: 100%;        
        min-height: 650px;
    }
 }

 @media only screen and (max-height: 812px) {
    .exercise--div-question-noimg {        
        font-size: 3.5rem;            
        min-height: 400px;
    }    
 }

 @media only screen and (max-height: 700px) {    
    .exercise--div-question-xs {        
        font-size: 1.5rem;                    
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (max-width: 400px) {
    .exercise--write-input {     
        margin-left: 1rem;        
    }
    .exercise--question-text-xlong-length {
        font-size: 1rem;
    }
    .exercise--question-text-long-length {
        font-size: 1.2rem;
    }
    .exercise--question-text-medium-length {
        font-size: 1.5rem;
    }
    .exercise--question-text-short-length {
        font-size: 2rem;
    }
 }

/* Small devices */
@media only screen and (max-width: 768px) {
    .exercise--img {
        max-height: 200px;
    }
    .exercise--secondary-button {
        height: 3rem;
        width: 3rem;
    }
    .exercise--question-text-xlong-length {
        font-size: 1.2rem;
    }
    .exercise--question-text-long-length {
        font-size: 1.5rem;
    }
    .exercise--question-text-medium-length {
        font-size: 2rem;
    }
    .exercise--hint-children {
        border: none !important;
        height: 5rem;        
    }
    .exercise--footer-fixed {
        padding: 0.5rem
    }
}
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    /* Smaller on larger screen sized */
    .exercise--img-small {
        max-height: 300px !important;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (max-width: 992px) {
    .exercise--question-text-xlong-length {
        font-size: 1.5rem;
    }
    .exercise--question-text-long-length {
        font-size: 1.7rem;
    }
    .exercise--question-text-medium-length {
        font-size: 2.2rem;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (max-width: 1200px) {
    .exercise--question-text-xlong-length {
        font-size: 1.5rem;
    }
    .exercise--question-text-long-length {
        font-size: 2.2rem;
    }
 } 

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1201px) {
    .exercise--question-text-xlong-length {
        font-size: 2rem;
    }
  } 

