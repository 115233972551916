/* Up to XS screen size */
 @media (max-width: 576px) {
    .exercise--prompt-border {
        border-bottom: 2px solid #CED4DA;
    }
}

 /* From XS screen size */
 @media (min-width: 577px) {
    .exercise--prompt-border {
        border-right: 2px solid #CED4DA;
    }
}