
.exercise--animation-container {
    position: relative;
    height: 24rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exercise--star-animation {
    position: absolute;
    width: 3rem;
    height: 3rem;
}

.exercise--feedback-image {    
    width: auto;
    height: 24rem;
}

@media only screen and (max-height: 700px) {
    .exercise--animation-container {
        height: 20rem;
    }
    .exercise--feedback-image {    
        height: 20rem;
    }
  }

@media only screen and (max-height: 650px) {
    .exercise--animation-container {
        height: 16rem;
    }
    .exercise--feedback-image {    
        height: 16rem;
    }
  }

@media only screen and (max-width: 576px) {
    .exercise--animation-container {
        height: 20rem;
    }
    .exercise--feedback-image {    
        height: 20rem;
    }
  }








