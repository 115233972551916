.comprehension--alt-base {
    border: 2px solid #9E78ED;
    border-radius: 5px;
    transition: transform .1s;
    -webkit-transition: transform .1s;
}

.comprehension--alt-img {
    width: auto;
    height: auto;
    max-height: 175px;
}

.comprehension--alt-img:hover {
    cursor: pointer;    
}

.comprehension--alt-img-zoom:hover { 
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
}

.comprehension--alt-correct {
    padding: 1rem;
    border: 2px solid #198754 !important;
}

.comprehension--alt-correct:hover {
    border: 2px solid #198754 !important;
    background-color: #ffffff;
    cursor: inherit;
    color: #212529;
}

.comprehension--alt-incorrect {
    padding: 1rem;
    border: 2px solid #dc3545;
}

.comprehension--alt-incorrect:hover {
    border: 2px solid #dc3545 !important;
    background-color: #ffffff;
    cursor: inherit;
    color: #212529;
}

.comprehension--alt-btn {    
    font-size: 2.5rem;
    background-color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.comprehension--alt-btn-sm {
    font-size: 2rem !important;
}

.comprehension--alt-undefined {
    border: 2px solid #9E78ED;
}

.comprehension--alt-btn:hover {
    background-color: #9E78ED;
    color: #ffffff;
    border: 2px solid #9E78ED;
}

.comprehension--alt-btn:focus {
    box-shadow: none;
}

.comprehension--alt-tag {
    font-size: 3rem;    
    background-color: #ffffff;
}

.comprehension--alt-tag-yesNo {
    font-size: 2rem;
    background-color: #ffffff;
}

.comprehension--alt-play {
    margin-left: 0.5rem;      
}

.comprehension--success {
    color: #198754;
    margin-right: 0.5rem;
}

.comprehension--fail {
    color: #dc3545;
    margin-right: 0.5rem;
}

.comprehension--footer-bg {    
    background-color: rgba(158, 119, 237, 0.1);    
    justify-content: center;
    align-items: center;
    flex-grow: 1 !important;    
}

.comprehension--alt-medium-length {    
    font-size: 1.8rem;
}
.comprehension--alt-long-length {    
    font-size: 1.5rem;
}
.comprehension--alt-xlong-length {    
    font-size: 1.2rem;
}

/* See AudioPlayer.css for audio button */
/* Tablet landscape */
@media only screen and (max-width: 1400px) and (max-height: 992px) {
    .comprehension--alt-img {        
        max-height: 150px;
    }
    .comprehension--alt-tag {
        font-size: 2rem;
    }
    .comprehension--alt-btn {
        font-size: 1.8rem;
    }
    .comprehension--alt-btn-lg {
        font-size: 4rem !important;
    }

    .comprehension--alt-medium-length {    
        font-size: 1.5rem;
    }
    .comprehension--alt-long-length {    
        font-size: 1.2rem;
    }
    .comprehension--alt-xlong-length {    
        font-size: 1rem;
    }
}

/* Tablet vertical */
@media only screen and (max-height: 1400px) and (max-width: 992px) {    
    .comprehension--alt-img {        
        max-height: 175px;        
    }
    .comprehension--alt-tag {
        font-size: 2rem;
    }
    .comprehension--alt-btn {
        font-size: 2rem;
    }
    .comprehension--alt-btn-lg {
        font-size: 4rem !important;
    }
    .comprehension--alt-medium-length {    
        font-size: 1.8rem;    
    }
    .comprehension--alt-long-length {    
        font-size: 1.5rem;
    }
    .comprehension--alt-xlong-length {    
        font-size: 1.2rem;
    }
}

/* Smartphone */
@media only screen and (max-width: 576px) {
    .comprehension--alt-img {
        max-height: 100px;
    }
    .comprehension--alt-tag {
        font-size: 1.5rem;
    }
    .comprehension--alt-btn {
        font-size: 2.5rem;
    }
    .comprehension--alt-btn-lg {
        font-size: 4rem !important;
    }
    .comprehension--alt-medium-length {    
        font-size: 1.2rem;
    }
    .comprehension--alt-long-length {    
        font-size: 1.1rem;
    }
    .comprehension--alt-xlong-length {    
        font-size: 1rem;
    }
}

 /* From Large screen width */
 @media only screen and (min-width: 991px) {
    .comprehension--alt-btn-sm {
        font-size: 2rem !important;
    }
}