.typing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8em;
    height: 4em;
    position: relative;
    padding: 10px;
    margin-left: 5px;
    background: white;
    border: 2px solid #CED4DA;
    border-radius: 3rem;
}
  
.typing__dot {
    float: left;
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
    background: black;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}
  
.typing__dot:nth-child(1) {
    animation-delay: 0s;
}
  
.typing__dot:nth-child(2) {
    animation-delay: 0.3s;
}
  
.typing__dot:nth-child(3) {
    animation-delay: 0.6s;
}
  
@keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
}