.exerciseLibraryCard--card-body {
    padding: 0 !important;
}

.exerciseLibraryCard--base-library-header {
    height: 2rem;
    background-color: rgba(158, 120, 237, 0.2);
    text-align: end;
    vertical-align: center;
}

.exerciseLibraryCard--own-exercise-header {
    height: 2rem;
    background-color: rgba(231, 101, 177, 0.2);
    color: #E765B1;
    text-align: end;
    vertical-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.25rem 1rem 0.25rem 1rem;
}

.exerciseLibraryCard--community-exercise-header {
    height: 2rem;
    background-color: rgba(23, 163, 171, 0.2);
    color: #17A3AB;
    text-align: end;
    vertical-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.25rem 1rem 0.25rem 1rem;
}

.exerciseLibraryCard--icon-button {
    min-width: 2.1rem;
    min-height: 2.1rem;
    border-radius: 4px;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem !important;
    color: #6C757D;
    cursor: pointer;
    &.selected {
        background-color: #CED4DA !important;
    }
}

.exerciseLibraryCard--icon-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.05);
}

.exerciseLibraryCard--small-font {
    font-size: 0.9rem;
}

.exerciseLibraryCard--title-icon {
    padding-right: 1rem;
}

.exerciseLibraryCard--title-icon > img {
    vertical-align: middle !important;
    height: 1.2rem !important;
}

.phonology-title-icon > img {
    height: 1rem !important;
}

.exerciseLibraryCard--header-icon {
    height: 2rem;
    padding: 0.5rem;
}


.exerciseLibraryCard--truncated-text {
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.exerciseLibraryCard--tag {
    border-color: #CED4DA;
    background-color: rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-radius: 0.5rem;
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: auto;
    width: fit-content;
}

.exerciseLibraryCard--exercise-select {
    transform: scale(1.2);
}

.exerciseLibraryCard--private-icon {
    max-width: 1.2rem;
    max-height: 1.2rem; 
    color: #6C757D;
}
   
   