@media only screen and (max-width: 820px) {
    .exerciseListPage--card-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, min(100%, 330px));
        grid-gap: 1.5rem;
        justify-content: center;
    }
} 

@media only screen and (min-width: 821px) { 
    .exerciseListPage--card-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, min(100%, 400px));
        grid-gap: 1.5rem;
        justify-content: center;
    }
}

.menu--language-select {
    color: rgba(0,0,0,.55);    
    width: 100%;
}

.exerciseMenu--type-wrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
}

.exerciseMenu--header {
    margin: 1rem 0rem 1rem 0rem;
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    align-items: center;
}

.exerciseMenu--languageSelector-wrapper {
    margin-left: auto;        
    max-width: max(10rem, 15%);
    min-width: 140px;
}

.exerciseMenu--logout {
    margin-left: auto;
    white-space: nowrap;
}

.exerciseMenu--patient-username {
    font-size: 1.1rem;
}

.exercisePage--loading-wrapper {    
    height: 40rem;
    display: flex;
    justify-content: center;    
    align-items: center;
}

.exercisePage--loading-icon {    
    width: 4rem;
    height: 4rem;    
}

.exercisePage--total-progress-bar {
    height: 1.5rem;
    background-color:rgba(25, 135, 84, 0.1);
}

.exercisePage--total-progress-bar1 {
    background-color: #198754;
}

.exercisePage--completed-label {
    color: #198754;
    font-size: 1.4rem;
}

.exercisePage--progress-subtitle {
    color: #6c757d;
    font-size: 1.4rem;
}

.exercisePage--total-progress-bar-finished {
    background-color: rgba(25,135,84,255);
}

.messagebox-accordion-header {
    display: flex;
    align-items: center;
}

.messagebox-accordion-header .accordion-button::after {
    margin-left: 8px
}

.messagebox-accordion-body {
    border: 1px solid #ddd; /* Change the color and thickness to your liking */
    border-top: none; /* To avoid the border merging with the header */
    padding: 15px; /* Add some padding inside the body for better visual spacing */
    border-radius: 0 0 5px 5px; /* Optional: Rounded corners for the bottom of the body */
}

/* Additional overrides to ensure no borders on the Accordion Item */
.messagebox-accordion-item {
    border: none !important; /* Remove border around the entire accordion item */
}

.accordion-button.collapsed{
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important; /* Remove any background color */
}