.exercise-filter--select {
    border-color: #CED4DA;
    background-color: rgba(0, 0, 0, 0.05);
    border-style: solid;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    /* cursor: pointer; */
    width: fit-content;
}

.exercise-filter--select input[type="checkbox"] {
    cursor: pointer;
}

.exercise-filter--type-select-selected {
    background-color: white;
}

.exercise-filter--type-select-pointer {
    cursor: pointer;
}

.exercise-filter--dropdown-menu { 
    min-width: 400px; /* Adjust the value as needed */
}

.exercise-sort--dropdown-button { 
    font-size: 0.9rem;
    color: #6C757D;
    border: none;
}

.exercise-sort--dropdown-button:hover { 
    border: none;
    color: #6C757D;
    background-color: rgba(0, 0, 0, 0.05);
}

.exercise-filter--tag-filter-vocabulary {
    color: #0D6D72;
    background-color: rgba(23, 163, 171, 0.2);
	border-color: #17A3AB;
   
    &:hover {
        color: #212529 !important;
        background-color: rgba(23, 163, 171, 0.2) !important;
        border-color: #17A3AB !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #00838F !important;
        border-color: #00838F !important;
    }
}

.exercise-filter--tag-filter-repetition {
    color: #7D418B;
	background-color: rgba(226, 100, 255, 0.2);
	border-color: #C96EDE;

    &:hover {
        color: #212529 !important;
        background-color: rgba(226, 100, 255, 0.2) !important;
        border-color: #C96EDE !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B537D2 !important;
        border-color: #B537D2 !important;
    }
}

.exercise-filter--tag-filter-automatic {
    color: #035935;
	background-color: rgba(12, 167, 102, 0.2);
	border-color: #0CA766;
    &:hover {
        color: #212529 !important;
        background-color: rgba(12, 167, 102, 0.2) !important;
        border-color: #0CA766 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #097B4C !important;
        border-color: #097B4C !important;
    }
}

.exercise-filter--tag-filter-grammar {
    color: #31157C;
    background-color: rgba(83, 56, 158, 0.2);
    border-color: #53389E;

    &:hover {
        color: #212529 !important;
        background-color: rgba(83, 56, 158, 0.2) !important;
        border-color: #31157C !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #53389E !important;
        border-color: #53389E!important;
    }
}

.exercise-filter--tag-filter-other {
    color: #723CE7;
    background-color: rgba(114, 60, 231, 0.1);
    border-color: #723CE7;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(114, 60, 231, 0.1) !important;
        border-color: #723CE7 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #723CE7 !important;
        border-color: #723CE7 !important;
    }
}

.exercise-filter--tag-filter-verbs {
    color: #1131B0;
    background-color: rgba(88, 113, 217, 0.2);
    border-color: #5871D9;

    &:hover {
        color: #212529 !important;
        background-color: rgba(88, 113, 217, 0.2) !important;
        border-color: #5871D9 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #2D4CC8 !important;
        border-color: #2D4CC8 !important;
    }
}

.exercise-filter--tag-filter-nouns {
    color: #975607;
    background-color: rgba(225, 194, 85, 0.2);
    border-color: #E78A1D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(225, 194, 85, 0.2) !important;
        border-color: #E78A1D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #AF6612 !important;
        border-color: #AF6612 !important;
    }
}

.exercise-filter--tag-filter-matching {
    color: #B82E00;
    background-color: rgba(222, 85, 42, 0.2);
    border-color: #DE552A;

    &:hover {
        color: #212529 !important;
        background-color: rgba(222, 85, 42, 0.2) !important;
        border-color: #DE552A !important;
    }

    &.selected {
        color: #ffffff;
        background-color:  #C7461F !important;
        border-color: #C7461F !important;
    }
}

.exercise-filter--tag-filter-semantics {
    color: #3F5C00;
    background-color: rgba(115, 167, 4, 0.1);
    border-color: #73A704;

    &:hover {
        color: #212529 !important;
        background-color: rgba(115, 167, 4, 0.1) !important;
        border-color: #73A704 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #598104 !important;
        border-color: #598104 !important;
    }
}

.exercise-filter--tag-filter-control {
    color: #323B3E;
    background-color: rgba(108, 117, 125, 0.1);
    border-color: #6C757D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(108, 117, 125, 0.1) !important;
        border-color: #6C757D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #6C757D !important;
        border-color: #6C757D !important;
    }
}

.exercise-filter--tag-filter-categorization {
    color: #AD0028;
    background-color: rgba(236, 78, 113, 0.2);
    border-color: #EC4E71;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(236, 78, 113, 0.2) !important;
        border-color: #EC4E71 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D32C33 !important;
        border-color: #D32C33 !important;
    }
}

.exercise-filter--tag-filter-puzzles {
    color: #005270;
    background-color: rgba(0, 143, 188, 0.2);
    border-color: #008FBC;

    &:hover {
        color: #212529 !important;
        background-color: rgba(0, 143, 188, 0.2) !important;
        border-color: #008FBC !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #0072C3 !important;
        border-color: #0072C3 !important;
    }
}

.exercise-filter--tag-filter-food_drink {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-nature {
    color: #0F5132;
    background-color: rgba(15, 81, 50, 0.1);
    border-color: #0F5132;

    &:hover {
        color: #212529 !important;
        background-color: rgba(15, 81, 50, 0.1) !important;
        border-color: #0F5132 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #0F5132 !important;
        border-color: #0F5132 !important;
    }
}

.exercise-filter--tag-filter-entertainment {
    color: #B41873;
    background-color: rgba(231, 101, 177, 0.2);
    border-color: #E765B1;

    &:hover {
        color: #212529 !important;
        background-color: rgba(231, 101, 177, 0.2) !important;
        border-color: #E765B1 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D52089 !important;
        border-color: #D52089 !important;
    }
}

.exercise-filter--tag-filter-default {
    color: #723CE7;
    background-color: rgba(114, 60, 231, 0.1);
    border-color: #723CE7;

    &:hover {
        color: #212529 !important;
        background-color: rgba(114, 60, 231, 0.1) !important;
        border-color: #723CE7 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #723CE7 !important;
        border-color: #723CE7 !important;
    }
}

.exercise-filter--tag-filter-language_awareness {
    color: #1131B0;
    background-color: rgba(88, 113, 217, 0.2);
    border-color: #5871D9;

    &:hover {
        color: #212529 !important;
        background-color: rgba(88, 113, 217, 0.2) !important;
        border-color: #5871D9 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #2D4CC8 !important;
        border-color: #2D4CC8 !important;
    }
}

.exercise-filter--tag-filter-phonological_awareness {
    color: #975607;
    background-color: rgba(225, 194, 85, 0.2);
    border-color: #E78A1D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(225, 194, 85, 0.2) !important;
        border-color: #E78A1D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #AF6612 !important;
        border-color: #AF6612 !important;
    }
}

.exercise-filter--tag-filter-f_sound {
    color: #0D6D72;
    background-color: rgba(23, 163, 171, 0.2);
	border-color: #17A3AB;
   
    &:hover {
        color: #212529 !important;
        background-color: rgba(23, 163, 171, 0.2) !important;
        border-color: #17A3AB !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #00838F !important;
        border-color: #00838F !important;
    }
}

.exercise-filter--tag-filter-g_sound {
    color: #7D418B;
	background-color: rgba(226, 100, 255, 0.2);
	border-color: #C96EDE;

    &:hover {
        color: #212529 !important;
        background-color: rgba(226, 100, 255, 0.2) !important;
        border-color: #C96EDE !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B537D2 !important;
        border-color: #B537D2 !important;
    }
}

.exercise-filter--tag-filter-k_sound {
    color: #723CE7;
    background-color: rgba(114, 60, 231, 0.1);
    border-color: #723CE7;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(114, 60, 231, 0.1) !important;
        border-color: #723CE7 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #723CE7 !important;
        border-color: #723CE7 !important;
    }
}

.exercise-filter--tag-filter-r_sound {
    color: #0F5132;
    background-color: rgba(15, 81, 50, 0.1);
    border-color: #0F5132;

    &:hover {
        color: #212529 !important;
        background-color: rgba(15, 81, 50, 0.1) !important;
        border-color: #0F5132 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #0F5132 !important;
        border-color: #0F5132 !important;
    }
}

.exercise-filter--tag-filter-s_sound {
    color: #31157C;
    background-color: rgba(83, 56, 158, 0.2);
    border-color: #53389E;

    &:hover {
        color: #212529 !important;
        background-color: rgba(83, 56, 158, 0.2) !important;
        border-color: #31157C !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #53389E !important;
        border-color: #53389E!important;
    }
}

.exercise-filter--tag-filter-t_sound {
    color: #B82E00;
    background-color: rgba(222, 85, 42, 0.2);
    border-color: #DE552A;

    &:hover {
        color: #212529 !important;
        background-color: rgba(222, 85, 42, 0.2) !important;
        border-color: #DE552A !important;
    }

    &.selected {
        color: #ffffff;
        background-color:  #C7461F !important;
        border-color: #C7461F !important;
    }
}

.exercise-filter--tag-filter-l_sound {
    color: #975607;
    background-color: rgba(225, 194, 85, 0.2);
    border-color: #E78A1D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(225, 194, 85, 0.2) !important;
        border-color: #E78A1D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #AF6612 !important;
        border-color: #AF6612 !important;
    }
}

.exercise-filter--tag-filter-m_sound {
    color: #1131B0;
    background-color: rgba(88, 113, 217, 0.2);
    border-color: #5871D9;

    &:hover {
        color: #212529 !important;
        background-color: rgba(88, 113, 217, 0.2) !important;
        border-color: #5871D9 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #2D4CC8 !important;
        border-color: #2D4CC8 !important;
    }
}

.exercise-filter--tag-filter-n_sound {
    color: #035935;
	background-color: rgba(12, 167, 102, 0.2);
	border-color: #0CA766;
    &:hover {
        color: #212529 !important;
        background-color: rgba(12, 167, 102, 0.2) !important;
        border-color: #0CA766 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #097B4C !important;
        border-color: #097B4C !important;
    }
}

.exercise-filter--tag-filter-b_sound {
    color: #AD0028;
    background-color: rgba(236, 78, 113, 0.2);
    border-color: #EC4E71;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(236, 78, 113, 0.2) !important;
        border-color: #EC4E71 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D32C33 !important;
        border-color: #D32C33 !important;
    }
}

.exercise-filter--tag-filter-p_sound {
    color: #323B3E;
    background-color: rgba(108, 117, 125, 0.1);
    border-color: #6C757D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(108, 117, 125, 0.1) !important;
        border-color: #6C757D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #6C757D !important;
        border-color: #6C757D !important;
    }
}

.exercise-filter--tag-filter-d_sound {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-v_sound {
    color: #AD0028;
    background-color: rgba(236, 78, 113, 0.2);
    border-color: #EC4E71;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(236, 78, 113, 0.2) !important;
        border-color: #EC4E71 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D32C33 !important;
        border-color: #D32C33 !important;
    }
}

.exercise-filter--tag-filter-sje_sound {
    color: #0D6D72;
    background-color: rgba(23, 163, 171, 0.2);
	border-color: #17A3AB;
   
    &:hover {
        color: #212529 !important;
        background-color: rgba(23, 163, 171, 0.2) !important;
        border-color: #17A3AB !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #00838F !important;
        border-color: #00838F !important;
    }
}

.exercise-filter--tag-filter-tje_sound {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-beginning {
    color: #035935;
	background-color: rgba(12, 167, 102, 0.2);
	border-color: #0CA766;
    &:hover {
        color: #212529 !important;
        background-color: rgba(12, 167, 102, 0.2) !important;
        border-color: #0CA766 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #097B4C !important;
        border-color: #097B4C !important;
    }
}

.exercise-filter--tag-filter-middle {
    color: #AD0028;
    background-color: rgba(236, 78, 113, 0.2);
    border-color: #EC4E71;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(236, 78, 113, 0.2) !important;
        border-color: #EC4E71 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D32C33 !important;
        border-color: #D32C33 !important;
    }
}

.exercise-filter--tag-filter-end {
    color: #323B3E;
    background-color: rgba(108, 117, 125, 0.1);
    border-color: #6C757D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(108, 117, 125, 0.1) !important;
        border-color: #6C757D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #6C757D !important;
        border-color: #6C757D !important;
    }
}

.exercise-filter--tag-filter-combinations {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-genitive {
    color: #3F5C00;
    background-color: rgba(115, 167, 4, 0.1);
    border-color: #73A704;

    &:hover {
        color: #212529 !important;
        background-color: rgba(115, 167, 4, 0.1) !important;
        border-color: #73A704 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #598104 !important;
        border-color: #598104 !important;
    }
}

.exercise-filter--tag-filter-plural {
    color: #0D6D72;
    background-color: rgba(23, 163, 171, 0.2);
	border-color: #17A3AB;
   
    &:hover {
        color: #212529 !important;
        background-color: rgba(23, 163, 171, 0.2) !important;
        border-color: #17A3AB !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #00838F !important;
        border-color: #00838F !important;
    }
}

.exercise-filter--tag-filter-tempus {
    color: #723CE7;
    background-color: rgba(114, 60, 231, 0.1);
    border-color: #723CE7;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(114, 60, 231, 0.1) !important;
        border-color: #723CE7 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #723CE7 !important;
        border-color: #723CE7 !important;
    }
}

.exercise-filter--tag-filter-comparative {
    color: #B82E00;
    background-color: rgba(222, 85, 42, 0.2);
    border-color: #DE552A;

    &:hover {
        color: #212529 !important;
        background-color: rgba(222, 85, 42, 0.2) !important;
        border-color: #DE552A !important;
    }

    &.selected {
        color: #ffffff;
        background-color:  #C7461F !important;
        border-color: #C7461F !important;
    }
}

.exercise-filter--tag-filter-syntax {
    color: #035935;
	background-color: rgba(12, 167, 102, 0.2);
	border-color: #0CA766;
    &:hover {
        color: #212529 !important;
        background-color: rgba(12, 167, 102, 0.2) !important;
        border-color: #0CA766 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #097B4C !important;
        border-color: #097B4C !important;
    }
}

.exercise-filter--tag-filter-negations {
    color: #AD0028;
    background-color: rgba(236, 78, 113, 0.2);
    border-color: #EC4E71;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(236, 78, 113, 0.2) !important;
        border-color: #EC4E71 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #D32C33 !important;
        border-color: #D32C33 !important;
    }
}

.exercise-filter--tag-filter-prepositions {
    color: #323B3E;
    background-color: rgba(108, 117, 125, 0.1);
    border-color: #6C757D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(108, 117, 125, 0.1) !important;
        border-color: #6C757D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #6C757D !important;
        border-color: #6C757D !important;
    }
}

.exercise-filter--tag-filter-pronouns {
    color: #975607;
    background-color: rgba(225, 194, 85, 0.2);
    border-color: #E78A1D;

    &:hover {
        color: #212529 !important;
        background-color: rgba(225, 194, 85, 0.2) !important;
        border-color: #E78A1D !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #AF6612 !important;
        border-color: #AF6612 !important;
    }
}

.exercise-filter--tag-filter-categories {
    color: #1131B0;
    background-color: rgba(88, 113, 217, 0.2);
    border-color: #5871D9;

    &:hover {
        color: #212529 !important;
        background-color: rgba(88, 113, 217, 0.2) !important;
        border-color: #5871D9 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #2D4CC8 !important;
        border-color: #2D4CC8 !important;
    }
}

.exercise-filter--tag-filter-emotions {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-questions {
    color: #005270;
    background-color: rgba(0, 143, 188, 0.2);
    border-color: #008FBC;

    &:hover {
        color: #212529 !important;
        background-color: rgba(0, 143, 188, 0.2) !important;
        border-color: #008FBC !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #0072C3 !important;
        border-color: #0072C3 !important;
    }
}

.exercise-filter--tag-filter-antonyms {
    color: #B82E00;
    background-color: rgba(222, 85, 42, 0.2);
    border-color: #DE552A;

    &:hover {
        color: #212529 !important;
        background-color: rgba(222, 85, 42, 0.2) !important;
        border-color: #DE552A !important;
    }

    &.selected {
        color: #ffffff;
        background-color:  #C7461F !important;
        border-color: #C7461F !important;
    }
}

.exercise-filter--tag-filter-synonyms {
    color: #035935;
	background-color: rgba(12, 167, 102, 0.2);
	border-color: #0CA766;
    &:hover {
        color: #212529 !important;
        background-color: rgba(12, 167, 102, 0.2) !important;
        border-color: #0CA766 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #097B4C !important;
        border-color: #097B4C !important;
    }
}

.exercise-filter--tag-filter-turn_taking {
    color: #005270;
    background-color: rgba(0, 143, 188, 0.2);
    border-color: #008FBC;

    &:hover {
        color: #212529 !important;
        background-color: rgba(0, 143, 188, 0.2) !important;
        border-color: #008FBC !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #0072C3 !important;
        border-color: #0072C3 !important;
    }
}

.exercise-filter--tag-filter-storytelling {
    color: #B125A3;
    background-color: rgba(195, 42, 180, 0.15);
    border-color: #B125A3;

    &:hover {
        color: #212529 !important;
        background-color: rgba(195, 42, 180, 0.15) !important;
        border-color: #B125A3 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #B125A3 !important;
        border-color: #B125A3 !important;
    }
}

.exercise-filter--tag-filter-interaction {
    color: #723CE7;
    background-color: rgba(114, 60, 231, 0.1);
    border-color: #723CE7;
    
    &:hover {
        color: #212529 !important;
        background-color: rgba(114, 60, 231, 0.1) !important;
        border-color: #723CE7 !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #723CE7 !important;
        border-color: #723CE7 !important;
    }
}

.exercise-filter--tag-filter-social_problem_solving {
    color: #31157C;
    background-color: rgba(83, 56, 158, 0.2);
    border-color: #53389E;

    &:hover {
        color: #212529 !important;
        background-color: rgba(83, 56, 158, 0.2) !important;
        border-color: #31157C !important;
    }

    &.selected {
        color: #ffffff;
        background-color: #53389E !important;
        border-color: #53389E!important;
    }
}