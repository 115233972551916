.images--container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    grid-gap: .5rem;
}

.images--card-container {        
    border: 1px solid rgba(0,0,0,0.1);    
    border-radius: 0.5rem;
    overflow: hidden;
}

.images--image {
    padding: 5px;
    border-radius: 0.5rem;
    max-width: 100% !important;
}

.image--edit-icon {
    font-size: 1.3rem;
    margin: 5px;    
    cursor: pointer;
}

.images--wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem;    
}

.images--button-delete {
    margin-left: 2rem;
}
