html {
	font-size: 1rem;
}

.user-select {
  user-select: text;
}

@media (max-width: 1200px) { /* Bootstrap Large grid */
  html {
    font-size: 1rem !important;
  }
}

@media (max-width: 992px) { /* Bootstrap Medium grid */
  html {
    font-size: 1rem !important;
  }
}

@media (max-width: 768px) { /* Bootstrap Small grid */
	html {
		font-size: 0.9rem !important;
	}
}

@media (max-width: 576px) { /* Bootstrap XS grid */
	html {
		font-size: 0.8rem !important;
	}
}


