@media only screen and (max-width: 820px) {
  .chatbot--scenario-list-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, min(100%, 330px));
      grid-gap: 1.5rem;
      justify-content: center;
  }
} 

@media only screen and (min-width: 821px) { 
  .chatbot--scenario-list-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, min(100%, 400px));
      grid-gap: 1.5rem;
      justify-content: center;
  }
}

.chatbot--scenario-container {
  padding: 0 !important;
}

  .chatbot--chat-container {
    overflow: scroll;
    font-size: 2rem;    
    height: 65dvh;

  
    /* 
    Below should be ensured in all places where we showcase text in the chatbot: 
  
    Make sure that below WCAG is satisfied with current text design. Just implementing below looks pretty bad: 
    - Line height to at least 1.5 times the font size 
    - Letter spacing to at least 0.12 times the font size 
    - Word spacing to at least 0.16 times the font size 
    - Spacing following paragraphs to at least 2 times the font size
    */
    /* line-height: 1.5; 
    letter-spacing: 0.12em; 
    word-spacing: 0.16em;  */
    /* margin-bottom: 2em; */
  }
  
  .chatbot--chat-input {
    font-size: 1.5rem;
  }
  
  .chatbot--user-message {  
    border-radius: 3rem 3rem 0.5rem 3rem;
    background-color: #0D6EfD;
    color: white;
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 14rem;
  }
  
  .chatbot--ai-message {  
    border-style: solid;
    border-color: #CED4DA;
    border-width: 2px;
    border-radius: 3rem 3rem 3rem 0.5rem;
    padding: 2rem;
    margin-top: 1rem; 
    margin-bottom: 1rem; 
    margin-right: 2rem;
  }
  
  .chatbot--ai-message-icon {
    color: #0D6EfD;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid #CED4DA;
    border-radius: 10px;
    padding: 1rem;
  }
  
  .chatbot--chat-button {
    margin: 0 1rem;
    display: inline;
    width: 10rem;  
    height: 8rem;  
  }  
  
  .chatbot--feedback-container {
    min-height: 75dvh;
    height: 30rem;
  }

  .chatbot--card-header {
    font-size: 1.5rem;
  } 
  
  .chatbot--card-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    margin-top:0.5rem;
  }
  
  .chatbot--card-text {
    font-size: 1.2rem;
  }
  
  .chatbot--header-title {
    font-size: 1.8rem;
  }
  
  .chatbot--confirmation-button {
    width: 40%;
  }
  

  .chatbot--header-turns {  
    border-radius: 10px; 
    width: 12rem; 
  }

  
  .chatbot--custom-modal {
    display: flex;
    justify-content: center;
  } 
  
  .chatbot--custom-modal {
    font-size: auto; /* Adjust font size as needed */
  }
  
  .chatbot--form-label {
    color: black;
  }

  .chatbot--footer {
    position: sticky;
    bottom:0 !important;
    height: 25dvh;
    min-height: 25dvh !important;
    max-height: 25dvh !important;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-top: auto;
  }

  .chatbot--header {
    position: sticky;
    top: 0 !important;
    height: 10dvh;
    max-height: 10dvh;
  }

  .chatbot--scenario-button:focus {
    border-width: 2px;
    background-color: rgba(0, 0, 0, 0.05);            ;
  }
  
  