/* Ensure that the tabs container takes up the full width of its parent */
.practice-page-tabs {
    display: flex;
    justify-content: center; /* Centers the tabs horizontally */
    width: 100%; /* Makes the tabs container take full width */
}

/* Styles for individual tab buttons */
.practice-page-tabs .nav-item {
    flex: 1; /* Makes each nav item take up equal space */
}

/* Styles for individual tab buttons */
.practice-page-tabs .nav-link {
    text-align: center; /* Centers the text and icon within each tab */
    width: 100%; /* Ensures the tab link takes full width of its parent */
    border-radius: 100px !important;

}

.practice-page--stats-box {
    background-color: #ECE4FB;
	border-radius: 2rem;
	color: #9E77ED;
}